import { motion } from "framer-motion";
import Link from 'next/link';
import styles from '@/styles/navbar.module.css'
import { useRouter } from "next/router";
import { useState } from "react";
import MenuChildDropdown from "./menuchilddropdown";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    userSelect: 'auto',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    userSelect: 'none',
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const MenuDropdown = ({ item }) => {

  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.li
      variants={variants}
      className={styles.menudropdownwrapper}
    >
        <div className={styles.menudropdown}>
            <div className={styles.menuheading} onClick={() => {setIsOpen(prev => !prev)}}>
                {item?.icon}
                {item?.name}
                <motion.svg animate={{ rotate: isOpen ? 180 : 0, y: isOpen ? -2 : 0 }} x="0px" y="0px" width="26px" viewBox="0 0 960 560" fill='currentColor'>
                    <g>
                        <path d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
                                c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
                                c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"/>
                    </g>
                </motion.svg>
            </div>
            <motion.div initial={{ height: 0 }} animate={{ height: isOpen ? 'auto' : 0 }} className={styles.menulistwrapper}>
                <div className={styles.menulist}>
                    {item?.items?.map(j => {
                      if(j?.type === 'dropdown') {
                        return <MenuChildDropdown key={j?.name} item={j} />
                      } else {
                        return (
                          <Link key={j?.name} scroll={false} href={j?.url}>{j?.name}</Link>
                        )
                      }
                    })}
                </div>
            </motion.div>
        </div>
    </motion.li>
  );
};

export default MenuDropdown;