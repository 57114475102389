import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Tooltip = ({ title, children, prevent, custom, reverse, ...props }) => {

  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(isMobile);
  }, []);

  const variants = {
    hidden: { opacity: 0, y: reverse ? -30 : 30 },
    visible: { opacity: 1, y: reverse ? -40 : 40 }
  };

  return (
    <div
      className={`relative flex justify-center items-center ${custom}`}
      onMouseEnter={() => {prevent ? !isMobile && setIsVisible(true) : setIsVisible(true)}}
      onMouseLeave={() => {prevent ? !isMobile && setIsVisible(false) : setIsVisible(false)}}
      onTouchStart={() => setIsVisible(false)}
    >
        <AnimatePresence>
            {isVisible &&
                <motion.p className={`${reverse && 'tooltipreverse'} tooltip`}
                    variants={variants}
                    initial={'hidden'}
                    animate={isVisible ? 'visible' : 'hidden'}
                    exit={'hidden'}
                    {...props}>
                    {title}
                </motion.p>}
        </AnimatePresence>
      {children}
    </div>
  );
};

export default Tooltip;