import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'

import Modal from '@/components/External/modal'
import UserPanel from './UserPanel'
import { useEffect } from 'react'

const UserModal = () => {

  const router = useRouter();

  const setUserModal = () => {
    router.replace({ query: {} })
  }

  const isOpen = router?.query?.user;

  useEffect(() => {
    const htmlRef = document.querySelector('html');
    if(isOpen) {
      htmlRef.style.overflow = 'hidden';
    } else {
      htmlRef.style.overflow = null;
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {router?.query?.user && <Modal full={true} setIsOpen={setUserModal}>
        <UserPanel setIsOpen={setUserModal} />
      </Modal>}
    </AnimatePresence>
  )
}

export default UserModal;