import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styles from '@/styles/navbar.module.css'
import { useTheme } from 'next-themes'
import { useStore } from '@/store/user'

import Tooltip from '@/components/External/tooltip'
import Switch from '@/components/External/switch'
import Button from '@/components/External/button'
import Currency from './currency'

import { currencies, checkRate } from '@/hooks/currencyData'


const Settings = ({ hidden }) => {

    const [expand, setExpand] = useState(false)
    const { theme, setTheme } = useTheme();
    const { currency, setCurrency } = useStore();

    const menuRef = useRef();
    const btnRef = useRef();

    useEffect(() => {
        const closeDropdown = e => {

            if(menuRef.current && !menuRef.current?.contains(e.target) && btnRef.current && !btnRef.current?.contains(e.target)) {
                setExpand(false)
            }
        }

        document.body.addEventListener('click', closeDropdown);

        return () => {document.body.removeEventListener('click', closeDropdown)}
    })

    useEffect(() => {
        const storedCurrency = localStorage.getItem("currency");
        if (storedCurrency) {
          const parsedCurrency = JSON.parse(storedCurrency);
          setCurrency(parsedCurrency);
          checkRate(parsedCurrency).then((rate) => {
            setCurrency((prevCurrency) => ({
              ...prevCurrency,
              rate: rate,
            }));
          });
        }
      }, []);

      useEffect(() => {
        let interval;
        if (currency.name !== 'USD') {
            interval = setInterval(async () => {
                const rate = await checkRate(currency);
                setCurrency((prevCurrency) => ({
                    ...prevCurrency,
                    rate: rate
                }));
            }, 60000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [currency]);

    useEffect(() => {
        if(hidden === true) {
            setExpand(false);
        }
    }, [hidden]);

    return (
        <div className={styles.settings} ref={btnRef}>

            <Tooltip title='Settings' prevent={true} custom='w-full h-full'>
                <Button custom={`rounded-full ${styles.stgout}`}>
                    <button className={styles.stg} aria-label="Settings" aria-expanded={expand ?? 'false'} aria-controls="settings" id="settings-btn" onClick={() => {setExpand(prev => !prev)}}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                    </button>
                </Button>
            </Tooltip>

            <AnimatePresence>

                {
                    expand &&
                    <motion.ul id="settings" ref={menuRef}
                        initial={{ scale: .7, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: .7, opacity: 0 }}
                        transition={{ duration: .2 }}>
                        <li tabIndex={0} onClick={() => {theme === 'light' ? setTheme('dark') : setTheme('light')}} onKeyDown={(e) => {e.key === 'Enter' ? theme === 'light' ? setTheme('dark') : setTheme('light') : null}}>
                            <label>Dark mode</label>
                            <div>
                                <Switch isOn={theme === 'dark' || (theme === 'system' && window?.matchMedia('(prefers-color-scheme: dark)'))} setIsOn={setTheme} />
                            </div>
                        </li>
                        <Currency currencies={currencies} currency={currency} setCurrency={setCurrency} checkRate={checkRate} />
                    </motion.ul>
                }

            </AnimatePresence>
        </div>
    )
}

export default Settings