import { motion } from "framer-motion";

function Switch({ isOn, setIsOn, ...props }) {
  
    return (
      <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={() => {setIsOn(prev => !prev)}} {...props}>
        <motion.div transition={{ type: "spring", stiffness: 700, damping: 30 }} layout />
      </div>
    );
  }

export default Switch