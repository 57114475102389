import Navbar from './Navbar'
import Footer from './Footer'
import UserModal from './Navbar/UserModal/UserModal'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'

const variants = {
  out: {
    opacity: 0,
    transition: {

    }
  },
  in: {
    opacity: 1,
    transition: {
      delay: .3
    }
  }
};

const Layout = ({ children }) => {

  const { pathname } = useRouter();

  return (
    <>
      <Navbar />
      <UserModal />
      <AnimatePresence initial={false} mode="popLayout" onExitComplete={() => {window.scrollTo({ top: 0, behavior: 'smooth'})}}>
        <motion.div key={pathname} variants={variants} animate={'in'} initial={'out'} exit="out">
          <AnimatePresence initial={true} mode="sync">
            <main id='main' style={{ WebkitTapHighlightColor: 'transparent', backgroundImage: pathname === '/404' ? "url('/film_grain.png'), url('/white_grain.png')" : null }}>
              {children}
              <Footer />
            </main>
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default Layout