import Web3 from 'web3';
import { getBasketCoinJson } from "@/hooks/chainType";

const checkAllowance = async (userAddress) => {

    if(userAddress?.length > 0) {
        try {
            const contractJson = getBasketCoinJson('Mainnet');

            const web3 = new Web3(window?.provider ?? 'https://bsc-dataseed1.binance.org:443');
            const stakingcontract = '0xe0c255a5d89b6d9fedb5c4e43c11341a072e3bcc';

            const contract = new web3.eth.Contract(contractJson.abi, contractJson.address);
            const allowance = await contract.methods.allowance(userAddress, stakingcontract).call();

            return parseInt(allowance);
        } catch(error) {
            console.log(error)
        }
    }
};

export default checkAllowance;