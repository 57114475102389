import Image from "next/image"
import styles from '@/styles/navbar.module.css'
import { useStore } from "@/store/user"
import { motion } from "framer-motion";

const variants = {
    open: {
      opacity: 1,
      userSelect: 'auto',
      pointerEvents: 'auto',
      transition: { delay: .5 }
    },
    closed: {
      opacity: 0,
      userSelect: 'none',
      pointerEvents: 'none',
    }
};

const Menuprice = ({ isOpen }) => {

    const { BSKTPrice, currency } = useStore();

  return (
    <motion.div className={styles.bsktprice} animate={isOpen ? 'open' : 'closed'} variants={variants}>
        <a href='https://www.dextools.io/app/en/bnb/pair-explorer/0x448bd09c288cc2594e8ef004e01d89e188ec6a01' target='_blank'>
            <Image src='/logo/round_logo.png' width={32} height={32} alt='BasketCoin round logo' />
            <p className="pl-3">{(BSKTPrice * currency?.rate)?.toFixed(currency?.float + 1 ?? 3)}</p>
            <p className={!currency?.symbol || currency?.symbol === '$' ? null : 'pl-1'}>{currency?.symbol ?? '$'}</p>
        </a>
    </motion.div>
  )
}

export default Menuprice