import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";

const Currency = ({ currencies, currency, setCurrency, checkRate }) => {

    const [isOpen, setIsOpen] = useState(false);

    const changeCurrency = async (o) => {
        try {
          let rate;
          if (o?.name === "USD") {
            rate = 1;
          } else {
            rate = await checkRate(o);
          }
    
          const selectedCurrency = {
            name: o?.name,
            symbol: o?.symbol,
            url: o?.url,
            float: o?.float,
            rate: rate,
          };
    
          setCurrency(selectedCurrency);
          localStorage.setItem("currency", JSON.stringify(selectedCurrency));
        } catch (error) {
          console.error("Error occurred:", error);
          setCurrency({ name: "USD", symbol: "$", url: "/USDIcon.svg", rate: 1, float: 2 });
          localStorage.removeItem("currency");
        }
      };

  return (
    <div>
        <li tabIndex={0} onClick={() => {setIsOpen(prev => !prev)}} onKeyDown={(e) => {e.key === 'Enter' ? setIsOpen(prev => !prev) : null}}>
            <label>Currency</label>
            <div>
                <Image style={{ height: '20px' }} src={currency?.url} width={20} height={20} alt='Selected currency' />
                <p>{currency?.name}</p>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
        </li>
        <AnimatePresence>
            {isOpen &&
                <motion.ul style={{ overflow: 'hidden' }} initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }}>
                    {currencies?.map(o => {
                        if(o?.name !== currency?.name) {
                            return (
                                <li key={o?.name} tabIndex={0} onClick={() => {changeCurrency(o)}} onKeyDown={(e) => {e?.key === 'Enter' ? changeCurrency(o) : null}}>
                                    <Image style={{ height: '20px' }} src={o?.url} width={20} height={20} alt={`${o.name} icon`} />
                                    <p>{o?.name} — ({o?.symbol})</p>
                                </li>
                            )
                        }
                    })}
                </motion.ul>
            }
        </AnimatePresence>
    </div>
  )
}

export default Currency