import Image from 'next/image'
import styles from '@/styles/panel.module.css'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import { useStore } from '@/store/user'

import Button from '@/components/External/button'
import Balance from './Balance'
import { useRouter } from 'next/router'

const UserPanel = ({ setIsOpen }) => {

  const { user, setIsConnected, resetUser } = useStore();

  const router = useRouter();

  const disconnect = () => {
    resetUser();
    localStorage.removeItem("currentProvider");
    setIsConnected(false);
    toast.success('Disconnected', {
      toastId: 'Disconnected'
    });
    setIsOpen(false);
    router.replace({ query: {} });
  }

  const copy = () => {
    navigator.clipboard.writeText(user?.account);
    toast.success('Address copied to clipboard', {
      toastId: 'copied'
    })
  }

  return (
    <div className={styles.panel}>
      <div className={styles.imgwrapper}>
        <Image src={`https://api.dicebear.com/5.x/identicon/svg?seed=${user?.account}.svg`} width={64} height={64} alt='userAvatar' />
      </div>
      <div className={styles.address} onClick={copy}>
        <p>{user?.account}</p>
        <button className='btn relative' onKeyDown={(e) => {e.key === 'Enter' ? copy : null}} aria-label='Copy address'>
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
        </button>
      </div>
      <Button custom={`${styles.connectbtn} ${styles.active} flex items-center mt-3`}>
        <div className='flex gap-2 px-3 items-center'>
          {user?.networkImg !== '' ? 
          <Image src={user?.networkImg ?? '/BNBIcon.png'} width={20} height={20} alt='networkIcon' /> : null}
          <p className='mt-px'>{user?.network !== '' ? user?.network : 'undefined'} ({user?.chainId})</p>
        </div>
      </Button>
      <div className='flex flex-col items-center justify-center gap-6 my-3'>

        <Balance title='Total Balance' value={Math.floor((user?.balance + user?.staked) / 10000000000000000) / 100} />

        <div className='flex gap-6 items-center justify-center flex-wrap'>

          <Balance title='Available' value={Math.floor(user?.balance / 10000000000000000) / 100} />

          <Balance title='Staked' value={Math.floor(user?.staked / 10000000000000000) / 100} />

        </div>

      </div>
      <button onClick={disconnect} className={styles.disconnect} aria-label='Disconnect'>
        Disconnect
        <svg viewBox="0 0 24 24" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path></svg>
      </button>
    </div>
  )
}

export default UserPanel;