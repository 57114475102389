import Web3 from "web3"

export const fetchPancake = async () => {
    let pancakeSwapAbi =  [
        {"inputs":[{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"}],"name":"getAmountsOut","outputs":[{"internalType":"uint256[]","name":"amounts","type":"uint256[]"}],"stateMutability":"view","type":"function"},
    ];
    let tokenAbi = [
        {"inputs":[],"name":"decimals","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
    ];
        
        
    let pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();

    const BUSDTokenAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" //BUSD
    const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //BNB

    const web3 = new Web3("https://bsc-dataseed1.binance.org");

    try {

        async function calcSell( tokensToSell, tokenAddres){
            
            let tokenRouter = await new web3.eth.Contract( tokenAbi, tokenAddres );
            let tokenDecimals = await tokenRouter.methods.decimals().call();
                
            tokensToSell = setDecimals(tokensToSell, tokenDecimals);
            let amountOut;
            try {
              let router = await new web3.eth.Contract( pancakeSwapAbi, pancakeSwapContract );
                amountOut = await router.methods.getAmountsOut(tokensToSell, [tokenAddres, BNBTokenAddress]).call();
                amountOut =  web3.utils.fromWei(amountOut[1], 'ether');
            } catch (error) {}
                
            if(!amountOut) return 0;
              return amountOut;
        }

        async function calcBNBPrice(){
            const web3 = new Web3("https://bsc-dataseed1.binance.org");
            const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //BNB
            let bnbToSell = web3.utils.toWei("1", "ether") ;
            let amountOut;
            try {
                let router = await new web3.eth.Contract( pancakeSwapAbi, pancakeSwapContract );
                amountOut = await router.methods.getAmountsOut(bnbToSell, [BNBTokenAddress, BUSDTokenAddress]).call();
                amountOut =  web3.utils.fromWei(amountOut[1], 'ether');
            } catch (error) {}
            if(!amountOut) return 0;
            return amountOut;
        }
        
        function setDecimals( number, decimals ){

            number = number.toString();
            let numberAbs = number.split('.')[0]
            let numberDecimals = number.split('.')[1] ? number.split('.')[1] : '';
            while( numberDecimals.length < decimals ){
                numberDecimals += "0";
            }
            return numberAbs + numberDecimals;
        }
            
            const tokenAddres = '0x4Dd1984a706E1c2C227bea67AD2F92dbdE30AfCE';
            let BNBPrice = await calcBNBPrice()
            let tokens_to_sell = 1; 
            const priceInBNB = await calcSell(tokens_to_sell, tokenAddres)/tokens_to_sell; // calculate TOKEN price in BUSD

            const bskt = priceInBNB*BNBPrice;

            return bskt;

    } catch (error) {
        console.log(error);
        return 0.07;
    }

}