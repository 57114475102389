import { useState } from 'react'
import styles from '@/styles/panel.module.css'
import { motion } from 'framer-motion'

import { useStore } from '@/store/user'

const Balance = ({ value, title }) => {

  const [isHovering, setIsHovering] = useState(false);

  const { currency, BSKTPrice } = useStore();

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.2, ease: "easeOut", delay: .1 } },
    hidden: { opacity: 0, scale: 0.8, transition: { duration: 0.2, ease: "easeIn" } }
  };

  
    return (
      <div
        className={styles.balance}
        onMouseEnter={() => {setIsHovering(true)}}
        onMouseLeave={() => {setIsHovering(false)}}
      >
        <div className={styles.balance2}>
          <span>{title}</span>
          <div className='relative flex justify-center items-center'>
            <motion.p variants={variants} initial={"visible"} animate={isHovering ? 'hidden' : 'visible'}>
              {value + ' BSKT'}
            </motion.p>
            {<motion.p variants={variants} initial={"hidden"} animate={isHovering ? 'visible' : 'hidden'} style={{ position: 'absolute' }}>
              {((value * BSKTPrice) * currency?.rate)?.toFixed(currency?.float ?? 2) + ' ' + currency?.symbol}
            </motion.p>}
          </div>
        </div>
      </div>
    );
  };

export default Balance