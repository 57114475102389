import basketCoin from "@/contracts/basketCoin.json";
import stakedCoin from '@/contracts/staking.json';

const getChainType = (chainId) => {
  const CHAIN_MAP = {
    "0x38": {'name': "Mainnet", 'icon': '/BNBIcon.png'},
    "0x61": {'name': "Testnet", 'icon': '/BNBIcon.png'},
    "0x1": {'name': "Ethereum", 'icon': '/eth.png'},
  };
  return CHAIN_MAP[chainId];
};

const getBasketCoinJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: basketCoin.address,
      abi: basketCoin.abi,
    };
  }
};

const getStakingJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: stakedCoin.address,
      abi: stakedCoin.abi,
    };
  }
};

const getGovernorContractJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: governorJson.address,
      abi: governorJson.abi,
    };
  }
};

const getBasketCoinNftJson = (chainType) => {
  if (chainType === "Mainnet") {
    return {
      address: basketCoinNftJsonTestnet.address,
      abi: basketCoinNftJsonTestnet.abi,
    };
  }
};

export { getGovernorContractJson, getBasketCoinNftJson, getChainType, getBasketCoinJson, getStakingJson };
