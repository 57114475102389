import { useEffect, useRef } from 'react'
import { motion, useCycle, AnimatePresence } from "framer-motion";
import styles from '@/styles/navbar.module.css'
import { useDimensions } from "../../../External/use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import Menuprice from './menuprice';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 32px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(20px at 40px 32px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const Menu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect(() => {
    const htmlRef = document.querySelector('html');
    if(isOpen) {
      htmlRef.style.overflow = 'hidden';
    } else {
      htmlRef.style.overflow = null;
    }
  }, [isOpen])

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      className={`${styles.navm} ${!isOpen ? styles.closed : null}`}
      ref={containerRef}
    >
      <MenuToggle toggle={() => toggleOpen()} isOpen={isOpen} />
      <AnimatePresence>
        {isOpen && <motion.div onClick={toggleOpen} style={{ width: '100vw', height: '100vh' }} 
          initial={{ background: 'hsla(0, 0%, 0%, 0)' }}
          animate={{ background: 'hsla(0, 0%, 0%, .15)' }}
          exit={{ background: 'hsla(0, 0%, 0%, 0)' }}
          transition={{ duration: .2 }} /> }
      </AnimatePresence>
      <motion.div className={styles.background} variants={sidebar} />
      <Navigation />
      <Menuprice isOpen={isOpen} />
    </motion.nav>
  );
}

export default Menu