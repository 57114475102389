import styles from '@/styles/navbar.module.css'
import { useState } from 'react'
import { motion } from 'framer-motion';

const Dropdown = ({ children, title }) => {

    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

  return (
    <li>
        <p tabIndex={0} onFocus={() => setIsHovered1(true)} onBlur={() => setIsHovered1(false)} onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)} className={`${isHovered1 || isHovered2 ? styles.isHovered : ''}`}>{title}</p>
        {isHovered1 || isHovered2 ? 
            <div onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)} className={styles.dropdownwrapper}>
                <motion.div className={styles.dropdown} initial={{ y: -20, opacity: 0 }} 
                            animate={{ y: 0, opacity: 1 }} transition={{ type: 'tween', duration: .3 }}>
                    {children}
                </motion.div>
            </div>
            : null
        }
    </li>
  )
}

export default Dropdown