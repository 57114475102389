import Web3 from "web3";
import { getBasketCoinJson, getStakingJson } from "@/hooks/chainType";

const getBalance = async (userAddress) => {

    if(userAddress?.length > 0) {
        try {
            const contractJson = getBasketCoinJson('Mainnet');
            const stakingJson = getStakingJson('Mainnet');

            const web3 = new Web3(window?.provider ?? 'https://bsc-dataseed1.binance.org:443');

            const balanceContract = new web3.eth.Contract(contractJson.abi, contractJson.address);
            const balance = await balanceContract.methods.balanceOf(userAddress).call();

            const stakingContract = new web3.eth.Contract(stakingJson.abi, stakingJson.address);
            const staked = await stakingContract.methods.balanceOf(userAddress).call();

            return {balance: parseInt(balance), staked: parseInt(staked)};
        } catch(error) {
            console.log(error)
        }
    }
    
};
  
export default getBalance;