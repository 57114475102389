import { motion } from "framer-motion";
import Link from 'next/link';
import styles from '@/styles/navbar.module.css'
import { useRouter } from "next/router";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    userSelect: 'auto',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    userSelect: 'none',
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({ item }) => {

  const router = useRouter();

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.05 }}
      whileFocus={{ scale: 1.05 }}
      className={router?.pathname === item?.to ? styles.active : null}
    >
      <Link href={item?.to} scroll={false}>
        {item?.icon}
        <span style={{ textTransform: 'Capitalize' }}>{item?.name}</span>
      </Link>
    </motion.li>
  );
};
