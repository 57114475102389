import { useState, useEffect } from 'react';
import styles from '@/styles/connect.module.css'
import Image from 'next/image';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import { useStore } from '@/store/user';
import { getChainType } from "@/hooks/chainType";
import Button from '@/components/External/button';
import checkAllowance from '@/hooks/checkAllowance';
import getBalance from '@/hooks/fetchBalance';

const Connect = () => {

  const [check, setCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, isConnected, setUser, setIsConnected, resetUser, setIsAdmin } = useStore();

  const router = useRouter();

  const openModal = () => {
    router?.push({ query: { user: true } })
  }

  const connectMetamask = async () => {

    setIsLoading(true);

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const chainId = `0x${Number(await ethereum.request({ method: 'eth_chainId' })).toString(16)}`;
      const chainTp = await getChainType(chainId);
      const balance = await getBalance(accounts[0] ?? null);
      const allowance = await checkAllowance(accounts[0] ?? null);

      if(chainTp) {
        setUser({ "account": accounts[0], 'chainId': chainId, 'network': chainTp?.name, 'networkImg': chainTp?.icon, 'balance': balance.balance, 'staked': balance.staked, 'allowance': allowance})
      } else {
        setUser({ "account": accounts[0], "chainId": chainId, 'networkImg': '/BNBIcon.png', 'network': '' });
      }

      localStorage.setItem("currentProvider", "Metamask");

      setIsConnected(true);

      setIsLoading(false);

      toast.success('Connected successfully', {
        toastId: 'Connected'
      });

      // Subscribe to accounts change
      window.ethereum.on("accountsChanged", async (accounts) => {
        if(accounts && accounts[0]?.length > 0) {
          
          const balance = await getBalance(accounts[0] ?? null)

          const allowance = await checkAllowance(accounts[0] ?? null)

          setUser({ "account": accounts[0], 'balance': balance.balance, 'staked': balance.staked, 'allowance': allowance });
          toast.success(`Account changed \n (${accounts[0]?.slice(0, 8)+' ... '+accounts[0]?.slice(accounts[0]?.length-6, accounts[0])})`, {
            toastId: 'Account'
          });

        } else {
          resetUser();
          localStorage.removeItem("currentProvider");
          setIsConnected(false);
          toast.success('Disconnected', {
            toastId: 'Disconnected'
          });
        }
      });

      // Subscribe to chainId change
      window.ethereum.on("chainChanged", async (chainId) => {
        const chainTp = await getChainType(chainId);
        if(chainTp) {
          setUser({ 'chainId': chainId, 'network': chainTp?.name, 'networkImg': chainTp?.icon})
        } else {
          setUser({ "chainId": chainId, 'network': '' });
        }
        toast.success(`Network switched (${chainId})`, {
          toastId: 'Chain'
        });
      });

      // Subscribe to session disconnection
      window.ethereum.on("disconnect", (code, reason) => {
        resetUser();
        localStorage.removeItem("currentProvider");
        setIsConnected(false);
        toast.success('Disconnected', {
          toastId: 'Disconnected'
        });
      });
    } catch (error) {
      console.log(error);
      if(error.code === -32002) {
        toast.warning('Already processing... \n Confirm in your wallet', {
          toastId: `MetaMaskError${error.code}`
        });
      } else {
        toast.error('Something went wrong. \n ' + error?.message, {
          toastId: 'MetaMaskError'
        });
      }
    }
  }

  useEffect(() => {
    if (typeof window.ethereum !== "undefined" || (typeof window.web3 !== "undefined")) {
        setCheck(true);
    } else {
        setCheck(false);
    }
  }, []);

  useEffect(() => {
    const currentProvider = localStorage.getItem("currentProvider");
    if (currentProvider && currentProvider === "Metamask" && window?.ethereum?.isMetaMask) {
      connectMetamask();
    }
  }, []);

  useEffect(() => {
    if(isConnected) {
      const check = async () => {
        const checkAdmin = (await import('@/hooks/checkadmin')).default;
        
        const isAdmin = await checkAdmin(user?.account);

        setIsAdmin(isAdmin);
      }
      check();
    }
  }, [isConnected, user])

  if(!check) return (
    <Button custom='reverse'>
      <a href='https://metamask.io/' target='_blank' className={`${styles.connectbtn} px-4 text-center flex justify-center items-center`}>
        Install MetaMask
      </a>
    </Button>
  )

  if(!isConnected) return (
    <Button custom='reverse'>
      <button className={`${styles.connectbtn} px-8`} onClick={connectMetamask}>
        Connect
      </button>
    </Button>
  );

  return (
    <div className='flex flex-row justify-center items-center gap-3 text-center'>
      <Button custom={`${styles.connectbtn} ${styles.active} flex items-center justify-center max-lg:hidden`}>
        <button className='flex gap-1 px-3 items-center w-full h-full'>
          {user?.networkImg !== '' ? 
          <Image src={user?.networkImg ?? '/BNBIcon.png'} width={26} height={26} alt='networkIcon' /> : null}
          <span className='mt-px'>{user?.network !== '' ? user?.network : 'undefined'}</span>
        </button>
      </Button>
      <Button custom={`${styles.connectbtn} ${styles.active} flex justify-center items-center`}>
        <button className='flex flex-row items-center pl-1 pr-2 gap-1 justify-center text-center w-full h-full' onClick={openModal}>
          <Image src={`https://api.dicebear.com/5.x/identicon/svg?seed=${user?.account}.svg`} width={26} height={26} alt='userAvatar' />
          <div title={user?.account}>
            <p>{Math.round(user.balance / 1000000000000000000)} BSKT</p>
            <p>{user?.account?.slice(0, 6) + '...' + user?.account?.slice(user.account.length - 4, user.account.length)}</p>
          </div>
        </button>
      </Button>
    </div>
  )
};

export default Connect;