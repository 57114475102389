import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import MenuDropdown from "./menudropdown";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    overflow: 'scroll'
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = () => (
  <motion.ul variants={variants} id='sidebar'>
    {itemIds.map((o, i) => (
      <MenuItem key={i} item={o} />
    ))}
    {dropdownItems.map((o, i) => (
      <MenuDropdown key={i} item={o} />
    ))}
  </motion.ul>
);

const itemIds = [{'to': '/', 'name': 'Home', 'icon': <svg fill="none" height="24px" width="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>},
                 {'to': '/staking', 'name': 'Staking', 'icon': <svg height='24px' viewBox="0 0 24 24" strokeWidth="2" fill="none" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" /></svg>},
                 {'to': '/stats', 'name': 'Statistics', 'icon': <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>}];

const dropdownItems = [
  {'name': 'Governance', 'icon': <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" /></svg>, 
    items: [
      {name: 'Overview', url: '/governance'}, {name: 'Polling', url: '/governance/polling'}, {name: 'Executive', url: '/governance/executive'}, {name: 'Create proposal', url: '/governance/create'}
    ]
  },
  {'name': 'Learn', 'icon': <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" /></svg>, 
    items: [
      {name: 'The Basket', url: '/basket'}, {name: 'BSKT Token', url: '/token'}, {name: 'Deflation', url: '/buyback'}, {name: 'Staking', url: '/stakinginfo'}, {name: 'BuyBack & Burn', url: '/buyback'}, {name: 'Terms & Conditions', url: '/terms'}
    ]
  },
  {'name': 'Follow', 'icon': <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" /></svg>, 
    items: [
      {type: 'dropdown', name: 'Telegram', arr: [{name: 'Global', url: 'https://t.me/bskt_global'}, {name: 'Announcements', url: 'https://t.me/bskt_global_announcement'}, {name: 'Polski', url: 'https://t.me/bskt_polska'}, {name: 'Powiadomienia', url: 'https://t.me/bsktcoin'}, {name: 'Philippines', url: 'https://t.me/bskt_philippines'}]}, {name: 'Twitter', url: 'https://twitter.com/Basketcoin_BSKT'}, {name: 'Medium', url: 'https://basketcoin.medium.com/'}, {name: 'LinkedIn', url: 'https://www.linkedin.com/company/basketcoin'}, {name: 'YouTube', url: 'https://www.youtube.com/channel/UCPbBbSr7ChQNMiUd1eBtBmw'}, {name: 'GitHub', url: 'https://github.com/BasketCoinBSKT'}
    ]
  }
]