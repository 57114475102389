import { create } from "zustand";
import Web3 from "web3";


const initialUser = { "account": '', "chainId": null, "network": '', 'networkImg': '', 'balance': 0, 'staked': 0, 'allowance': 0 }

export const useStore = create((set, get) => ({

    user: initialUser,
    setUser: (state) => set((prev) => ({ user: { ...prev.user, ...state } })),
    resetUser: () => set({ user: initialUser }),

    isConnected: false,
    setIsConnected: (state) => set(() => ({ isConnected: state })),

    isAdmin: false,
    setIsAdmin: (state) => set(() => ({ isAdmin: state })),

    cardsList: [],

    BSKTPrice: 0,
    setBSKTPrice: (state) => set(() => ({ BSKTPrice: state })),

    currency: {'name': 'USD', 'rate': 1, 'symbol': '$', 'float': 2, 'url': '/USDIcon.svg'},
    setCurrency: (state) => set((prev) => ({ currency: { ...prev.currency, ...state } })),

    web3: null,
    connect: () => {
        const { web3 } = get();
        if (!web3) {
          const web3Instance = new Web3('https://bsc-dataseed1.binance.org');
          set({ web3: web3Instance });
        }
    },

}))