import { useEffect, useState } from 'react';
import styles from '@/styles/navbar.module.css'
import { motion, useScroll } from 'framer-motion';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Connect from './Navbar/connect';
import Settings from './Navbar/Settings';
import Menu from './Navbar/Menu/Menu';
import Dropdown from './Navbar/dropdown';
import ChildDropdown from './Navbar/childdropdown';
import { fetchPancake } from '@/hooks/fetchPancake';
import { useStore } from '@/store/user';

const items = [
  {
    title: 'Learn',
    items: [
      {name: 'The Basket', url: '/basket'}, {name: 'BSKT Token', url: '/token'}, {name: 'Deflation', url: '/buyback'}, {name: 'Staking', url: '/stakinginfo'}, {name: 'BuyBack & Burn', url: '/buyback'}, {name: 'Terms & Conditions', url: '/terms'}
    ]
  },
  {
    title: 'Follow',
    items: [
      {type: 'dropdown', name: 'Telegram', arr: [{name: 'Global', url: 'https://t.me/bskt_global'}, {name: 'Announcements', url: 'https://t.me/bskt_global_announcement'}, {name: 'Polski', url: 'https://t.me/bskt_polska'}, {name: 'Powiadomienia', url: 'https://t.me/bsktcoin'}, {name: 'Philippines', url: 'https://t.me/bskt_philippines'}]}, {name: 'Twitter', url: 'https://twitter.com/Basketcoin_BSKT'}, {name: 'Medium', url: 'https://basketcoin.medium.com/'}, {name: 'LinkedIn', url: 'https://www.linkedin.com/company/basketcoin'}, {name: 'YouTube', url: 'https://www.youtube.com/channel/UCPbBbSr7ChQNMiUd1eBtBmw'}, {name: 'GitHub', url: 'https://github.com/BasketCoinBSKT'}
    ]
  }
]

const Navbar = () => {

  const [hidden, setHidden] = useState(false);
  const [initial, setInitial] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [logo, setLogo] = useState(false);

  const { BSKTPrice, setBSKTPrice } = useStore();
  const { scrollY } = useScroll();
  const router = useRouter();

  // BSKT Price fetching

  const fetchData = async () => {
    const res = await fetchPancake();
    if(res !== BSKTPrice) {
      setBSKTPrice(res)
    }
  }

  useEffect(() => {

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 8000);
  
    return () => {
      clearTimeout(intervalId);
    };
  }, [])
  
  // Navbar operations

  const update = () => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 20 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
    if (scrollY?.current > 50) {
      setShadow(true)
    } else {
      setShadow(false)
    }
  }

  const handleLogo = () => {
    if(window?.scrollY > 1) {
      window?.scrollTo({ top: 0, behavior: 'smooth' });
    } else if(router.pathname !== '/') {
      router.push('/');
    }
  }

  useEffect(() => {
    update();
    const unsubscribe = scrollY.on("change", update);
    return () => unsubscribe();
  }, [scrollY]);

  const variants = {
    visible: { opacity: 1, y: 0, transition: { ease: [0.1, 0.25, 0.3, 1], duration: 0.6 } },
    hidden: { opacity: 0, y: '-100%', transition: { ease: [0.1, 0.25, 0.3, 1], duration: 0.6 } }
  };
  
  return (
    <motion.header
      id='home'
      className={styles.header}
      variants={variants}
      initial={{ y: -50, opacity: 0 }}
      animate={
        !initial
          ? { y: 0, opacity: 1, transition: { type: 'spring', delay: .5 } }
          : hidden
          ? 'hidden'
          : 'visible'
      }
      onAnimationComplete={() => setInitial(true)}
      viewport={{ once: true }}
      layout
      style={{ background: router?.pathname === '/404' ? 'none' : null }}
    >
      <a href='#main' aria-label='Skip Navigation' className={styles.skip}>Skip navigation</a>

      <nav className={`${styles.navbar} ${shadow && styles.shadow}`}>

        <div className={styles.spacing} />

        <Menu />

        <div className={styles.logo} onMouseEnter={() => {!logo ? setLogo(true) : null}}>
          <motion.button type='button' onClick={handleLogo} alt={`Go to homepage`} aria-label={`Go to homepage`}
          initial={{ scale: 1 }} animate={logo ? { scale: [1, .85, .85, 1.05, 0.97, 1.02, 1] } : { scale: 1 }} transition={{ scale: { duration: 1 }, duration: 0, delay: 0 }} onAnimationComplete={() => {setLogo(false)}} layout>

            <svg className='max-sm:hidden' height="34px" viewBox="0 0 241 44" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit='2'>
                <g transform="matrix(1,0,0,1,-1,-1)">
                    <g id="Warstwa-1">
                        <g transform="matrix(1,0,0,1,1,1)">
                            <path d="M21.818,0C33.868,0 43.637,9.768 43.637,21.818C43.637,33.868 33.868,43.637 21.818,43.637C9.769,43.637 0,33.868 0,21.818C0,9.768 9.769,0 21.818,0ZM15.737,15.273L7.756,15.273L5.455,10.909L7.148,10.909L13.434,10.91L15.737,15.273ZM18.507,32.727L9.736,16.112L16.18,16.112L22.648,28.364L30.777,28.364L31.738,26.182L28.807,20.541L20.202,20.541L17.914,16.112L32.865,16.112L38.182,26.182L35.23,32.727L18.507,32.727" fill="rgb(0,148,255)"/>
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <path d="M240,13.307L240,30.277L237.426,30.277L228.139,18.786L228.139,30.277L225.036,30.277L225.036,13.307L227.61,13.307L236.897,24.798L236.897,13.307L240,13.307Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <rect x="215.677" y="13.307" width="3.128" height="16.97" fill="currentColor"/>
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0521)">
                            <path d="M182.717,30.52C181.016,30.52 179.481,30.144 178.109,29.393C176.738,28.641 175.663,27.602 174.885,26.277C174.108,24.952 173.719,23.457 173.719,21.792C173.719,20.128 174.112,18.633 174.898,17.308C175.683,15.982 176.762,14.944 178.133,14.192C179.505,13.441 181.041,13.065 182.741,13.065C184.12,13.065 185.379,13.308 186.518,13.792C187.657,14.277 188.619,14.98 189.405,15.901L187.384,17.817C186.165,16.491 184.665,15.829 182.885,15.829C181.73,15.829 180.696,16.083 179.781,16.592C178.867,17.101 178.153,17.808 177.64,18.714C177.127,19.619 176.87,20.645 176.87,21.792C176.87,22.94 177.127,23.966 177.64,24.871C178.153,25.776 178.867,26.483 179.781,26.992C180.696,27.502 181.73,27.756 182.885,27.756C184.665,27.756 186.165,27.085 187.384,25.744L189.405,27.683C188.619,28.604 187.653,29.307 186.506,29.792C185.359,30.277 184.096,30.52 182.717,30.52" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <path d="M162.411,15.974L156.83,15.974L156.83,13.307L171.121,13.307L171.121,15.974L165.539,15.974L165.539,30.277L162.411,30.277L162.411,15.974Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,-13.2751)">
                            <path d="M141.191,30.277L141.191,27.635L153.486,27.635L153.486,30.277L141.191,30.277Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,0.7856)">
                            <path d="M141.191,23.222L141.191,20.629L152.451,20.629L152.451,23.222L141.191,23.222Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,15.3796)">
                            <path d="M153.822,13.307L153.822,15.95L141.191,15.95L141.191,13.307L153.822,13.307Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <path d="M127.767,23.174L125.096,25.938L125.096,30.277L121.969,30.277L121.969,13.307L125.096,13.307L125.096,22.01L133.421,13.307L136.933,13.307L129.86,20.895L137.366,30.277L133.709,30.277L127.767,23.174Z" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0521)">
                            <path d="M110.372,30.52C109.073,30.52 107.818,30.334 106.607,29.962C105.396,29.59 104.438,29.097 103.732,28.483L104.815,26.035C105.504,26.584 106.351,27.033 107.353,27.38C108.355,27.728 109.362,27.901 110.372,27.901C111.623,27.901 112.558,27.699 113.175,27.295C113.793,26.892 114.101,26.358 114.101,25.695C114.101,25.211 113.929,24.811 113.584,24.495C113.239,24.18 112.802,23.934 112.273,23.756C111.744,23.578 111.022,23.376 110.108,23.15C108.825,22.843 107.786,22.536 106.992,22.229C106.198,21.922 105.516,21.441 104.947,20.786C104.378,20.132 104.093,19.247 104.093,18.132C104.093,17.194 104.346,16.342 104.851,15.574C105.356,14.807 106.118,14.197 107.136,13.744C108.155,13.291 109.402,13.065 110.877,13.065C111.904,13.065 112.914,13.194 113.909,13.453C114.903,13.712 115.762,14.083 116.483,14.568L115.497,17.017C114.759,16.58 113.989,16.249 113.187,16.023C112.385,15.796 111.607,15.683 110.853,15.683C109.619,15.683 108.7,15.893 108.099,16.313C107.497,16.734 107.196,17.291 107.196,17.986C107.196,18.471 107.369,18.867 107.714,19.174C108.059,19.481 108.496,19.724 109.025,19.902C109.554,20.079 110.276,20.281 111.19,20.508C112.441,20.798 113.468,21.101 114.27,21.417C115.072,21.732 115.758,22.213 116.327,22.859C116.896,23.506 117.181,24.378 117.181,25.477C117.181,26.415 116.928,27.263 116.423,28.023C115.918,28.782 115.152,29.388 114.125,29.841C113.099,30.293 111.848,30.52 110.372,30.52" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <path d="M92.16,16.459C89.163,23.512 88.842,24.239 86.289,30.277L83.066,30.277L90.644,13.307L93.724,13.307L101.326,30.277L98.054,30.277C95.74,24.88 94.838,22.714 92.16,16.459" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,1.0524)">
                            <path d="M77.388,21.453C78.318,21.76 79.056,22.273 79.601,22.992C80.147,23.711 80.419,24.604 80.419,25.671C80.419,27.142 79.858,28.277 78.735,29.077C77.612,29.877 75.976,30.277 73.827,30.277L65.455,30.277L65.455,27.804L73.635,27.804C76.057,27.804 77.268,26.988 77.268,25.356C77.268,23.723 76.057,22.907 73.635,22.907L65.455,22.907L65.455,20.459L73.009,20.459C74.1,20.459 74.938,20.261 75.523,19.865C76.109,19.469 76.402,18.891 76.402,18.131C76.402,17.356 76.109,16.77 75.523,16.374C74.938,15.978 74.1,15.78 73.009,15.78L65.455,15.78L65.455,13.307L73.346,13.307C75.335,13.307 76.867,13.699 77.941,14.483C79.016,15.267 79.553,16.345 79.553,17.719C79.553,18.56 79.361,19.303 78.976,19.95C78.591,20.596 78.062,21.097 77.388,21.453" fill="currentColor" />
                        </g>
                        <g transform="matrix(1,0,0,1,1,0.9798)">
                            <path d="M194.389,25.909C192.475,22.31 193.336,17.975 196.221,15.355L197.607,17.962C196.069,19.754 195.699,22.376 196.875,24.587C198.051,26.798 200.43,27.957 202.776,27.684L204.163,30.291C200.377,31.218 196.302,29.508 194.389,25.909ZM200.129,13.308C203.885,12.439 207.901,14.15 209.797,17.716C211.693,21.281 210.866,25.568 208.044,28.196L206.651,25.576C208.128,23.786 208.468,21.213 207.311,19.037C206.154,16.862 203.831,15.705 201.522,15.928L200.129,13.308Z" fill="currentColor" />
                        </g>
                    </g>
                </g>
            </svg>

            <svg className='sm:hidden' width="34" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="rgb(0,148,255)">
                <path d="M2332 5109 c-202 -18 -464 -76 -648 -144 -150 -55 -405 -185 -534 -270 -383 -257 -682 -596 -880 -1000 -161 -327 -236 -606 -262 -973 -24 -335 30 -721 147 -1038 55 -150 185 -405 271 -534 255 -382 596 -682 999 -880 327 -161 606 -236 972 -262 544 -39 1124 116 1582 424 499 335 874 845 1036 1410 312 1084 -105 2223 -1045 2853 -129 85 -384 215 -534 270 -335 123 -741 176 -1104 144z m-623 -1518 c72 -137 131 -252 131 -255 0 -3 -210 -5 -467 -4 l-468 3 -55 105 c-30 58 -90 171 -133 253 l-78 147 470 0 469 0 131 -249z m571 -1081 l377 -715 477 0 477 0 56 125 55 125 -61 120 c-33 66 -111 216 -171 333 l-111 212 -504 0 -504 0 -54 103 c-30 56 -91 173 -136 260 l-81 157 878 0 879 0 312 -591 312 -591 -173 -384 -173 -384 -983 3 -984 2 -454 860 c-250 473 -481 911 -514 973 l-59 112 380 -2 381 -3 378 -715z"/>
              </g>
            </svg>

          </motion.button>
        </div>

        <ul id="navigation" className='select-none'>
          <li><Link href='/' scroll={false}>Home</Link></li>
          <Dropdown title='dApps'>
            <div className={styles.split}>
              <div className={styles.droplinks}>
                <h3>dApps</h3>
                <Link href='/staking' scroll={false}>
                  <svg height='24px' viewBox="0 0 24 24" strokeWidth="2" fill="none" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" /></svg>
                  Staking
                </Link>
                <Link href='/stats' scroll={false}>
                  <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>
                  Statistics
                </Link>
                <Link href='/stats#calculator' scroll={false}>
                <svg fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                </svg>
                  Calculator
                </Link>
              </div>
              <div className={styles.droplinks}>
                <h3>Governance</h3>
                <Link href='/governance' scroll={false}>Overview</Link>
                <Link href='/governance/polling' scroll={false}>Polling</Link>
                <Link href='/governance/executive' scroll={false}>Executive</Link>
                <Link href='/governance/create' scroll={false}>Create proposal</Link>
              </div>
            </div>
          </Dropdown>
          <Dropdown title='Learn'>
            <div className={styles.droplinks}>
              {items[0]?.items?.map(o => {
                return (
                  <Link key={o?.name} href={o?.url} scroll={false}>{o?.name}</Link>
                )
              })}
            </div>
          </Dropdown>
          <Dropdown title='Follow'>
            <div className={styles.droplinks}>
              {items[1]?.items?.map(o => {
                if(o?.type === 'dropdown') {
                  return <ChildDropdown key={o?.name} item={o} />
                } else {
                  return (
                    <a key={o?.name} href={o?.url} target='_blank'>{o?.name}</a>
                  )
                }
              })}
            </div>
          </Dropdown>
        </ul>

        <div className={styles.user}>
          <Connect />
          <Settings hidden={hidden} />
        </div>

      </nav>

    </motion.header>
  )
}

export default Navbar;